import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { PieChart, Pie, Sector } from 'recharts'

import { apiUrl, keyToken } from '../../config'

import Api from '../../services/api'

import Toast from '../../components/Toast'
import { IsSSR } from "../../helpers/isSSR";
import { getStorageItem } from "../../helpers/storage";

class PollingVote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingDetailPolling: false,
            dataDetailPolling: {},
            size: '',
            loadingSubmitted: false,
            dataDetailChoose: [],
            activeIndex: 0,
            pieResult: [],
            totalResult: 0,
            statusError: false
        };
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        if (!this.isSSR) window.scrollTo(0, 0);
        this.fetchData()
    }

    handleChange(event) {
        this.setState({
            size: event.target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.size !== '') this.onSubmitted();
        else Toast('You have not made a choice')
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index
        })
    };

    onSubmitted() {
        this.setState({ statusError: false });
        // if (this.token) {
            const { size } = this.state;
            const { match } = this.props;
            const id = match.params.id;

            this.setState({ loadingSubmitted: true }, () => {
                const data = {
                    polling_id: id,
                    polling_answer_id: size
                };
                fetch(`${apiUrl}/polling_choice/create`, {
                    method: 'POST',
                    headers: [
                        ['Content-Type', 'application/json;text/plain'],
                        // ['x-access-token', this.token]
                    ],
                    body: JSON.stringify(data)
                })
                    .then(function (response) {
                        return response.json()
                    })
                    .then(response => {
                        if (response.status === 'success')
                            Toast('Thanks', 'success');
                        else this.checkError(response);
                        this.setState({ loadingSubmitted: true });
                        this.setState({ size: '' });
                        this.fetchData()
                    })
            })
        // } else {
        //     Toast('You need to login first before vote!');
        //     this.props.history.push(`/auth`)
        // }
    }

    fetchData() {
        const { match } = this.props;
        const id = match.params.id;

        this.setState({ loadingDetailPolling: true }, () => {
            const route = `/polling/${id}`;
            Api.get(route, null)
                .then(response => {
                    this.setState({ dataDetailPolling: response.data });
                    this.setState({ loadingDetailPolling: false });
                    if (response.data.polling_choices) {
                        let group = response.data.polling_choices
                            .map(item => {
                                return item.polling_answer_id
                            })
                            .filter((item, i, ar) => {
                                return ar.indexOf(item) === i
                            })
                            .sort((a, b) => a - b)
                            .map(item => {
                                let new_list = response.data.polling_choices
                                    .filter(
                                        itm => itm.polling_answer_id == item
                                    )
                                    .map(itm => {
                                        return itm.user_id
                                    });
                                let answer_name = response.data.polling_choices
                                    .filter(
                                        itm => itm.polling_answer_id == item
                                    )
                                    .map(itm => {
                                        return itm.polling_answer
                                    });
                                return {
                                    group: item,
                                    users: new_list,
                                    answer: answer_name
                                }
                            });

                        this.setState({ dataDetailChoose: group });
                        this.setResult()
                    }
                })
                .catch(error => {
                    this.setState({ loadingDetailPolling: false })
                })
        })
    }

    setResult() {
        const { dataDetailChoose } = this.state;
        let lists = [];
        let total = 0;
        dataDetailChoose.map(ddc => {
            let data = {};
            data.name = ddc.answer[0].answer;
            data.value = ddc.users.length;
            total += ddc.users.length;
            lists.push(data)
        });
        this.setState({ pieResult: lists, totalResult: total })
    }

    checkError(response) {
        this.setState({ statusError: true });
        if (response.status && response.status === 'failed') {
            if (response.error) {
                this.setState({ errors: response.error });
                Toast(response.message);
                if (response.message === 'Fail to Authentication.')
                    this.props.history.push(`/auth`)
            } else {
                Toast(response.message)
            }
        } else {
            Toast('Error internet connection')
        }
    }

    render() {
        const {
            dataDetailPolling,
            loadingSubmitted,
            pieResult,
            totalResult
        } = this.state;

        const renderActiveShape = props => {
            const RADIAN = Math.PI / 180;
            const {
                cx,
                cy,
                midAngle,
                innerRadius,
                outerRadius,
                startAngle,
                endAngle,
                fill,
                payload,
                percent,
                value
            } = props;
            const sin = Math.sin(-RADIAN * midAngle);
            const cos = Math.cos(-RADIAN * midAngle);
            const sx = cx + (outerRadius + 10) * cos;
            const sy = cy + (outerRadius + 10) * sin;
            const mx = cx + (outerRadius + 30) * cos;
            const my = cy + (outerRadius + 30) * sin;
            const ex = mx + (cos >= 0 ? 1 : -1) * 22;
            const ey = my;
            const textAnchor = cos >= 0 ? 'start' : 'end';

            return (
                <g>
                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                        {payload.name}
                    </text>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 6}
                        outerRadius={outerRadius + 10}
                        fill={fill}
                    />
                    <path
                        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                        stroke={fill}
                        fill="none"
                    />
                    <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                    <text
                        x={ex + (cos >= 0 ? 1 : -1) * 12}
                        y={ey}
                        textAnchor={textAnchor}
                        fill="#cc0000"
                    >{`${value} orang`}</text>
                    <text
                        x={ex + (cos >= 0 ? 1 : -1) * 12}
                        y={ey}
                        dy={18}
                        textAnchor={textAnchor}
                        fill="#cc0000"
                    >
                        {`(${(percent * 100).toFixed(2)}%)`}
                    </text>
                </g>
            )
        };

        return (
            <div>
                <div className="container">
                    <ul className="breadcrumbs">
                        <li className="breadcrumbs__item">
                            <Link to="/" className="breadcrumbs__url">
                                Home
							</Link>
                        </li>
                        <li className="breadcrumbs__item breadcrumbs__item--current">
                            Polling
						</li>
                    </ul>
                </div>

                <div className="main-container container" id="main-container">
                    <div className="row">
                        {!loadingSubmitted ? (
                            <div className="col-md-12">
                                <div className="entry card">
                                    <div className="entry__header d-flex polling-heading">
                                        <h2>{dataDetailPolling.title}</h2>
                                    </div>
                                    <div className="polling-body">
                                        <p
                                            className="polling-content"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    dataDetailPolling.question
                                            }}
                                        />
                                        {dataDetailPolling.polling_answers ? (
                                            <form onSubmit={this.handleSubmit}>
                                                {dataDetailPolling.polling_answers.map(
                                                    (pa, paIndex) => (
                                                        <div
                                                            className="border-dashboard d-flex pt-2 pb-2 mb-3"
                                                            key={paIndex}
                                                            style={{
                                                                paddingLeft: 16,
                                                                paddingRight: 16
                                                            }}
                                                        >
                                                            <input
                                                                type="radio"
                                                                name="pilih"
                                                                className="d-block input-jawaban m-0"
                                                                value={pa.id}
                                                                checked={
                                                                    this.state
                                                                        .size ===
                                                                    pa.id
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                            <span className="m-0">
                                                                <p
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            pa.answer
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    )
                                                )}{' '}
                                                <input
                                                    disabled={loadingSubmitted}
                                                    type="submit"
                                                    className="btn btn-md btn-color btn-button mt-5"
                                                    value={
                                                        loadingSubmitted
                                                            ? 'Loading...'
                                                            : 'Vote Now'
                                                    }
                                                />
                                            </form>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        ) : (
                                <div className="col-md-12 center">
                                    <h2>
                                        {this.state.statusError
                                            ? dataDetailPolling.title
                                            : 'Terima kasih atas partisipasi anda mengikuti polling kami'}
                                    </h2>
                                    <strong>
                                        {this.state.statusError
                                            ? ''
                                            : dataDetailPolling.title}
                                    </strong>
                                    <p
                                        className="polling-content"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                dataDetailPolling.question
                                        }}
                                    />
                                    <div className="entry card">
                                        <div className="polling-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2>Result</h2>
                                                </div>
                                                <div className="col-md-12">
                                                    <PieChart
                                                        width={400}
                                                        height={400}
                                                    >
                                                        <Pie
                                                            activeIndex={
                                                                this.state
                                                                    .activeIndex
                                                            }
                                                            activeShape={
                                                                renderActiveShape
                                                            }
                                                            data={pieResult}
                                                            cx={200}
                                                            cy={200}
                                                            innerRadius={60}
                                                            outerRadius={80}
                                                            fill="#cc0000"
                                                            dataKey="value"
                                                            onMouseEnter={
                                                                this.onPieEnter
                                                            }
                                                        />
                                                    </PieChart>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Total Voter : {totalResult}</p>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        )
    }
}

export default PollingVote
