import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import moment from 'moment';

import { IsSSR } from '../helpers/isSSR';

class V2Foto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isSSR = IsSSR();
    this.sliderRef = React.createRef();
  }

  renderImage(dataFoto) {
    const imageUrl = dataFoto.post?.mediaable?.media?.url || '/assets/img/favicon-ivoox.png';
    return <img src={imageUrl} className="entry__img lazyloaded" alt="" style={{ aspectRatio: '4/3', objectFit: 'cover', objectPosition: 'center' }} />;
  }

  handleNext = () => {
    this.sliderRef.current.slickNext();
  };
  handlePrev = () => {
    this.sliderRef.current.slickPrev();
  };

  render() {
    const { datas, loading } = this.props;
    console.log('V2Foto datas:', datas);

    if (loading) return null;
    return (
      <section className="section mb-24">
        <div style={{ paddingLeft: 0, paddingRight: 0 }} className="infografik">
          <div className="title-wrap title-wrap--line">
            <div className="row">
              <div className="col-md-6" style={{ width: '50%' }}>
                <Link
                  to={{
                    pathname: `/category/foto`
                  }}
                >
                  <h3 className="section-title">FOTO</h3>
                </Link>
              </div>
              {/* <input onChange={this.handleInputChange} type="range" min={0} max={3} /> */}
              <div className="col-md-6 d-flex align-items-center justify-content-end " style={{ gap: '1rem' }}>
                <Link
                  to={{
                    pathname: `/category/foto`
                  }}
                  style={{ fontSize: '18px', textDecoration: 'underline', fontWeight: 'bold', color: 'black' }}
                >
                  See All
                </Link>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={this.handlePrev}
                    className="px-3 py-2 "
                    style={{ border: '1px solid rgba(12, 12, 12, 0.4)', borderRadius: '2px 0 0 2px', background: 'none' }}
                  >
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 12L0 6L6 0L7.4 1.4L2.8 6L7.4 10.6L6 12Z" fill="#0C0C0C" fill-opacity="0.4" />
                    </svg>
                  </button>
                  <button
                    onClick={this.handleNext}
                    className="px-3 py-2 "
                    style={{ border: '1px solid rgba(12, 12, 12, 0.4)', borderRadius: '0 2px 2px 0', background: 'none' }}
                  >
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.6 6L0 1.4L1.4 0L7.4 6L1.4 12L0 10.6L4.6 6Z" fill="#0C0C0C" fill-opacity="0.4" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Slider
            ref={this.sliderRef}
            {...{
              dots: false,
              infinite: true,
              speed: 500,
              slidesToShow: !this.isSSR && window.outerWidth < 480 ? 1 : 3,
              slidesToScroll: !this.isSSR && window.outerWidth < 480 ? 1 : 3,
              arrows: false
            }}
          >
            {datas.map((data, dataIndex) => (
              <div className="infografik-card2" key={dataIndex}>
                <Link
                  to={{
                    pathname: `/${data.post.url}`,
                    search: `?tag_from=${data.post.tagables[0].tag.name || 'yahoo'}`
                  }}
                  style={{ position: 'relative' }}
                >
                  {this.renderImage(data)}
                  <div style={{ position: 'absolute', background: 'rgba(0,0,0,0.3)', color: 'white', bottom: 0, padding: '1rem', width: '100%' }}>
                    <h5 style={{ color: 'white' }}>{data.post.title}</h5>
                    <span style={{ fontSize: '11px' }}> {moment(data.post.createdAt).format('LLLL')}</span>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}

export default V2Foto;
